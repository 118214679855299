<template>
  <!-- 每日一句购买 -->
  <div class="study">
    <div class="wrap">
      <audio :src="url" preload="auto"></audio>
      <img src="@/assets/img/buy_text.png" alt="" class="buy_text">
      <div class="juan">
        <div class="text">
          <div class="title">曾子文化活动规则</div>
          <span>1、支付99元学费，加入“曾子文化每日一句经典学习”打卡园</span>
          <span>2、连续打卡学习80天，即可返还99元学费并赠送精美礼品一份（包邮）</span>
          <span>3、分享赚积分，每成功分享一人平台奖励10积分，积分可累加，积分可在平台商城兑换相应商品</span>
        </div>
        <div v-if="!play_show" class="shiting"><img src="@/assets/img/buy_play.png" alt="" @click="play"></div>
        <div v-else class="shiting"><img src="@/assets/img/buy_stop.png" alt="" @click="stop"></div>
        <div class="mianfei">免费试听</div>
      </div>
      <div class="bottom">
        <span @click="buy" v-if="buy_show">立即加入</span>
        <span @click="$router.push({name: 'study',query:{time}})" v-else>立即学习</span>
      </div>
    </div>
    <!-- 提示 -->
    <fadeAlert :msg="msg" v-if="showAlert" @hideFade="showAlert=false"></fadeAlert>
    <loading :ifload="ifload"></loading>
  </div>
</template>

<script>
import fadeAlert from '@/components/fadeAlert'
import loading from '@/components/loading'
export default {
  components: {
    fadeAlert,
    loading,
  },
  data() {
    return {
      headerName: '每日一句经典学习',
      msg: '', // 提示内容
      showAlert: false, // 提示显示
      ifload: false, // loading
      url: '',
      play_show: false,
      back_show: true,
      info: {},
      buy_show: true
    }
  },
  created () {
    if (this.$route.query.token) {
      localStorage.setItem('token',this.$route.query.token)
    }
  },
  mounted () {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
      var time = ''
      this.back_show = false;
      var date = new Date()
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date .getDate();
      if (month < 10) {
        month = '0' + month;
      }
      time = year + '-' + month + '-' + day;
      this.time = time;
      // 今日学习
      this.$get(`/ClockInc/everydayStudy?ymd=${time}`,{},(res)=>{
        if (res.data.code == 1 || res.data.code == 0) {
          this.buy_show = false;
        }
        if (res.data.code == 2) {
          this.buy_show = true;
        }
      })
    } else {
      this.back_show = true;
    }
    // 试听音频
    this.$get('/publics/getConfig?name=audition_audio',{},(res)=>{
      if (res.data.code == 1) {
        this.url = res.data.data;
      }
    })
  },
  methods: {
    play () {
      $("audio")[0].play();
      this.play_show = true;
    },
    stop () {
      $("audio")[0].pause();
      this.play_show = false;
    },
    buy () {
      this.$router.push({name: 'payment'})
    },
    back () {
      this.$router.push({name: 'Home'})
    }
  }
}
</script>

<style lang='scss' scoped>
.study {
  width: 100%;
  height: 100%;
  .head {
    position: relative;
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    text-align: center;
		position: fixed;
		left: 0;
		top: 0;
    z-index: 2;
    font-weight: bold;
    i {
      position: absolute;
      left: 10px;
      top: 15px;
      display: inline-block;
      width: 10px;
      height: 10px;
      border-left: 2px solid #000;
      border-bottom: 2px solid #000;
      transform: rotate(45deg);
    }
  }
  .wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/img/buy_bg.png');
    background-size: 100% 100%;
    text-align: center;
    .buy_text {
      width: 65%;
      margin-top: 80px;
    }
    .juan {
      position: relative;
      width: 100%;
      height: 450px;
      background: url('../../assets/img/buy_juan.png');
      background-size: 100% 100%;
      .text {
        position: absolute;
        left: 15%;
        top: 15%;
        width: 70%;
        .title {
          font-size: 20px;
          font-weight: bold;
          color: #453000;
        }
        span {
          display: block;
          color: #533B00;
          font-size: 14px;
          margin: 10px 0;
          text-align: left;
          font-weight: bold;
        }
      }
      .shiting {
        position: absolute;
        bottom: 100px;
        left: 0;
        text-align: center;
        width: 100%;
        text-align: center;
        img {
          width: 80px;
        }
      }
      .mianfei {
        position: absolute;
        bottom: 80px;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
      }
    }
    .bottom {
      position: fixed;
      bottom: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 20px 0;
      background: white;
      span {
        padding: 10px 40px;
        background: #F92A02;
        color: white;
        font-size: 14px;
        border-radius: 20px;
      }
    }
  }
}
</style>